:root {
  --bulb-blue: #17aafc;
  --bulb-blue-muted: #17aafc80;
  --bulb-blue-muted-shadow: #17aafc33;
  --bulb-yellow: #e09d22;
  --bulb-yellow-muted: #e09d2280;
  --bulb-yellow-muted-shadow: #e09d2233;
  --bulb-green: #07c838;
  --bulb-green-muted: #07c83880;
  --bulb-green-muted-shadow: #07c83833;
  --bulb-red: #df3039;
  --bulb-red-muted: #df303980;
  --bulb-red-muted-shadow: #df303933;
}

.wire {
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
  user-select: none;
  border-bottom: 3px solid #222;
  width: 100%;
  margin-top: -25px;
  padding: 0;
}

.bulb:before {
  content: "";
  background: #444;
  border-radius: 3px;
  width: 14px;
  height: 8px;
  position: absolute;
  top: -4px;
  left: 0;
}

.bulb {
  background: #fff;
  border-radius: 50%;
  width: 15px;
  height: 35px;
  margin: 0 20px;
  padding: 0;
  list-style: none;
  animation-name: even-bulb;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  display: inline-block;
  position: relative;
  top: 37px;
}

.bulb:nth-child(4n+1) {
  animation-name: bulb-blue;
}

.bulb:nth-child(4n+2) {
  animation-name: bulb-yellow;
}

.bulb:nth-child(4n+3) {
  animation-name: bulb-green;
}

.bulb:nth-child(4n+4) {
  animation-name: bulb-red;
}

@keyframes bulb-blue {
  0%, 100% {
    background: var(--bulb-blue);
    box-shadow: 0px 2px 20px 4px var(--bulb-blue);
  }

  50% {
    background: var(--bulb-blue-muted);
    box-shadow: 0px 2px 20px 4px var(--bulb-blue-muted-shadow);
  }
}

@keyframes bulb-yellow {
  50% {
    background: var(--bulb-yellow);
    box-shadow: 0px 2px 20px 4px var(--bulb-yellow);
  }

  0%, 100% {
    background: var(--bulb-yellow-muted);
    box-shadow: 0px 2px 20px 4px var(--bulb-yellow-muted-shadow);
  }
}

@keyframes bulb-green {
  0%, 100% {
    background: var(--bulb-green);
    box-shadow: 0px 2px 20px 4px var(--bulb-green);
  }

  50% {
    background: var(--bulb-green-muted);
    box-shadow: 0px 2px 20px 4px var(--bulb-green-muted-shadow);
  }
}

@keyframes bulb-red {
  50% {
    background: var(--bulb-red);
    box-shadow: 0px 2px 20px 4px var(--bulb-red);
  }

  0%, 100% {
    background: var(--bulb-red-muted);
    box-shadow: 0px 2px 20px 4px var(--bulb-red-muted-shadow);
  }
}

@media screen and (width < 500px) {
  .bulb:nth-child(n+5) {
    display: none;
  }
}

@media screen and (width < 1000px) {
  .bulb:nth-child(n+9) {
    display: none;
  }
}
/*# sourceMappingURL=index.c9e7e913.css.map */
