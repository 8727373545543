:root {
  --bulb-blue: rgba(23, 170, 252, 1);
  --bulb-blue-muted: rgba(23, 170, 252, 0.5);
  --bulb-blue-muted-shadow: rgba(23, 170, 252, 0.2);

  --bulb-yellow: rgba(224, 157, 34, 1);
  --bulb-yellow-muted: rgba(224, 157, 34, 0.5);
  --bulb-yellow-muted-shadow: rgba(224, 157, 34, 0.2);

  --bulb-green: rgba(7, 200, 56, 1);
  --bulb-green-muted: rgba(7, 200, 56, 0.5);
  --bulb-green-muted-shadow: rgba(7, 200, 56, 0.2);

  --bulb-red: rgba(223, 48, 57, 1);
  --bulb-red-muted: rgba(223, 48, 57, 0.5);
  --bulb-red-muted-shadow: rgba(223, 48, 57, 0.2);
}

.wire {
  width: 100%;

  text-align: center;
  white-space: nowrap;
  pointer-events: none;
  user-select: none;

  padding: 0;
  margin-top: -25px;

  border-bottom: 3px solid #222;
}

.bulb:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 8px;
  border-radius: 3px;
  top: -4px;
  left: 0;
  background: #444;
}

.bulb {
  position: relative;
  list-style: none;

  width: 15px;
  height: 35px;
  top: 37px;
  border-radius: 50%;

  margin: 0 20px;
  padding: 0;

  display: inline-block;
  background: #fff;

  animation-name: even-bulb;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.bulb:nth-child(4n + 1) {
  animation-name: bulb-blue;
}

.bulb:nth-child(4n + 2) {
  animation-name: bulb-yellow;
}

.bulb:nth-child(4n + 3) {
  animation-name: bulb-green;
}

.bulb:nth-child(4n + 4) {
  animation-name: bulb-red;
}

@keyframes bulb-blue {
  0%,
  100% {
    background: var(--bulb-blue);
    box-shadow: 0px 2px 20px 4px var(--bulb-blue);
  }

  50% {
    background: var(--bulb-blue-muted);
    box-shadow: 0px 2px 20px 4px var(--bulb-blue-muted-shadow);
  }
}

@keyframes bulb-yellow {
  50% {
    background: var(--bulb-yellow);
    box-shadow: 0px 2px 20px 4px var(--bulb-yellow);
  }

  0%,
  100% {
    background: var(--bulb-yellow-muted);
    box-shadow: 0px 2px 20px 4px var(--bulb-yellow-muted-shadow);
  }
}

@keyframes bulb-green {
  0%,
  100% {
    background: var(--bulb-green);
    box-shadow: 0px 2px 20px 4px var(--bulb-green);
  }

  50% {
    background: var(--bulb-green-muted);
    box-shadow: 0px 2px 20px 4px var(--bulb-green-muted-shadow);
  }
}

@keyframes bulb-red {
  50% {
    background: var(--bulb-red);
    box-shadow: 0px 2px 20px 4px var(--bulb-red);
  }

  0%,
  100% {
    background: var(--bulb-red-muted);
    box-shadow: 0px 2px 20px 4px var(--bulb-red-muted-shadow);
  }
}

@media screen and (width < 500px) {
  .bulb:nth-child(n + 5) {
    display: none;
  }
}

@media screen and (width < 1000px) {
  .bulb:nth-child(n + 9) {
    display: none;
  }
}
